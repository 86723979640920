input:focus {
  outline: 0;
}
.Login {
  overflow: hidden;
}
.Login .Login-back {
  text-indent: 1em;
}
.Login .Login-logo {
  width: 144px;
  height: 46px;
  margin: 70px 181px 0 0;
}

.Login .input-control {
  border: none;
  border-bottom: solid 1px #ccc;
  font-size: larger;
  width: 100%;
}

.Login .Login-tips {
  display: flex;
  /* flex-direction: column; */
  flex-wrap: nowrap;
  justify-content: space-between;
}
.Login .Login-tips span {
  /* display: block; */
  color: #ed7730;
  font-weight: bold;
}
