.m-bottom-bar {
  position: fixed;
  bottom: 0;
  height: 84px;
  border-top: 1px solid #f0f0f0;
  z-index: 1001;
  background-color: #fff;
  width: 100vw;
  color: rgba(95, 95, 95, 1);
}
.m-bottom-bar .bar-active {
  color: rgba(255, 100, 30, 1);
}
