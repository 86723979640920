.ant-menu-light.ant-menu-horizontal > .ant-menu-item-selected {
  color: rgb(255, 100, 80);
}

.ant-menu-light .ant-menu-submenu-selected > .ant-menu-submenu-title {
  color: rgb(255, 100, 80);
}

.ant-menu-light.ant-menu-horizontal > .ant-menu-submenu-selected::after {
  border-bottom-color: rgb(255, 100, 80);
}

.ant-menu-light.ant-menu-horizontal > .ant-menu-item-selected::after {
  border-bottom-color: rgb(255, 100, 80);
}
.ant-menu-light.ant-menu-horizontal > .ant-menu-submenu:hover::after {
  border-bottom-color: rgb(255, 100, 80);
}

.ant-menu-light.ant-menu-horizontal > .ant-menu-item:hover::after {
  border-bottom-color: rgb(255, 100, 80);
}
.ant-menu-light.ant-menu-horizontal > .ant-menu-item::after {
  border-bottom-color: transparent;
}

.ant-menu-light.ant-menu-horizontal > .ant-menu-submenu::after {
  border-bottom-color: transparent;
}

.ant-menu-light > .ant-menu .ant-menu-item-selected {
  color: rgb(255, 100, 80);
}

.pc-top-nav-bar a {
  text-decoration: none;
  color: black;
  transition: 0.2s;
}

.pc-top-nav-bar .login-register-group {
  position: absolute;
  right: 0px;
}
.pc-top-nav-bar .login-register-group a {
  font-size: 14px;
  line-height: 50px;
}
.pc-top-nav-bar .login-register-group li {
  padding: 0 3px 0 3px;
  line-height: 50px;
  display: inline-flex;
}
.pc-top-nav-bar .login-register-group li :hover {
  color: rgb(255, 100, 80);
}

/* 用户悬停 */
.pc-top-nav-bar #user-profile {
  margin: 0px 10px 0 0;
  right: 0;
  line-height: 16px;
  position: absolute;
  z-index: 101;
  border: 1px solid rgba(31, 35, 41, 0.15);
  border-radius: 5px;
  background-color: #fff;
  box-shadow: rgba(31, 35, 41, 0.1) 0px 4px 8px 0px;
  display: none;
}

.pc-top-nav-bar #user-name:hover #user-profile {
  display: block;
}

.pc-top-nav-bar .user-profile-tab {
  color: #000;
  padding: 0 11px 0 11px;
  overflow-wrap: break-word;
  padding: 24px 0 24px 0;
}
.pc-top-nav-bar .user-profile-tab:hover {
  background-color: rgba(31, 35, 41, 0.08);
  color: rgba(255, 100, 3, 1);
}
