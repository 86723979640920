.ItemDetailMain {
  height: 100%;
  background-color: #f8f8f8;
  padding-bottom: 100px;
  /* position: relative; */
}
.ItemDetailMain .part-1 {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  padding-top: 52px;
  line-height: 25px;
  font-size: 18px;
}
.ItemDetailMain .part-2 {
  background-color: #fff;
  padding: 8px 0 12px 0;
}
.ItemDetailMain .part-2 img {
  width: 100vw;
}
.ItemDetailMain .token {
  font-size: 10px;
  font-weight: bold;
  color: rgba(255, 100, 30, 1);
}
.ItemDetailMain .price {
  line-height: 25px;
  font-size: 18px;
  font-weight: 600;
  color: rgba(255, 100, 30, 1);
}
.ItemDetailMain .part-3 {
  background-color: #fff;
  margin: 10px 0 0 0;
  padding: 0 0 20px 16px;
  box-sizing: border-box;
  justify-content: space-between;
  display: flex;
}
.ItemDetailMain .part-3 span {
  display: inline-block;
  padding: 18px 20px 0 0;
}

.ItemDetailMain .part-4 {
  margin: 10px 0 0 0;
  background-color: #fff;
  padding: 18px 16px;
  box-sizing: border-box;
}

.ItemDetailMain .part-4 h4 {
  font-size: 14px;
  margin: 0 0 15px 0;
}
.ItemDetailMain .part-4 div {
  font-size: 14px;
  color: #666;
}

.bottomOption {
  background-color: #fff;
  width: 100%;
  height: 85px;
  position: fixed;
  bottom: 0;
  border-top: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 12px 16px 20px 26px;
  z-index: 1002;
}
.ItemDetailPage button {
  background-color: rgba(255, 100, 30, 1);
  border-radius: 23px;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  border: none;
}

/* 购买选项与遮罩 */
.ItemDetailPage .buyOptionPage {
  height: 100%;
  width: 100vw;
  position: absolute;
  z-index: 1002;
  background-color: rgba(0, 0, 0, 0.5);
}

.ItemDetailPage .buyOptionPage .buyOptionCard {
  position: absolute;
  bottom: 0;
  width: 100vw;
  height: 60vh;
  background: #fff;
}
.ItemDetailPage .buyOptionPage .buyOptionCard .buyOptionCard-item-1,
.ItemDetailPage .buyOptionPage .buyOptionCard .buyOptionCard-item-4 {
  padding: 20px 15px 0 10px;
  box-sizing: border-box;
  display: flex;
}
.ItemDetailPage .buyOptionPage .buyOptionCard .buyOptionCard-item-2,
.ItemDetailPage .buyOptionPage .buyOptionCard .buyOptionCard-item-3 {
  padding: 20px 15px 0 10px;
  box-sizing: border-box;
}
.ItemDetailPage .buyOptionPage .buyOptionCard .buyOptionCard-item-4 {
  justify-content: space-between;
}

.ItemDetailPage .buyOptionPage .buyOptionCard .buyOptionCard-item-lables {
  display: flex;
  flex-flow: wrap;
  gap: 15px;
  box-sizing: border-box;
  padding: 10px 0 0 0;
}
.ItemDetailPage
  .buyOptionPage
  .buyOptionCard
  .buyOptionCard-item-lables
  .choose-lable {
  padding: 5px 10px;
  color: rgba(160, 160, 162, 1);
  border: 1px solid rgba(160, 160, 162, 1);
  box-sizing: border-box;
  border-radius: 16px;
  font-size: 9px;
}
.ItemDetailPage
  .buyOptionPage
  .buyOptionCard
  .buyOptionCard-item-lables
  .choose-lable-active {
  padding: 5px 10px;
  color: rgba(255, 100, 30, 1);
  border: 1px solid rgba(255, 100, 30, 1);
  box-sizing: border-box;
  border-radius: 16px;
  font-size: 9px;
}
.ItemDetailPage
  .buyOptionPage
  .buyOptionCard
  .buyOptionCard-item-lables
  .choose-invalid-lable {
  padding: 5px 10px;
  color: rgba(160, 160, 162, 1);
  background-color: rgba(216, 216, 216, 0.3);
  /* border: 1px solid rgba(160, 160, 162, 1); */
  box-sizing: border-box;
  border-radius: 16px;
  font-size: 9px;
}
.ItemDetailPage .buyOptionPage .buyOptionCard .buyOptionCard-item-size {
  display: flex;
  justify-content: space-between;
  width: 65px;
}
.ItemDetailPage .buyOptionPage .buyOptionCard .buyOptionCard-item-size img {
  width: 16px;
  height: 16px;
}
.ItemDetailPage .buyOptionPage .buyOptionCard .buyOptionCard-item-size span {
  line-height: 16px;
}
