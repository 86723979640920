.m-account-info {
  padding: 0 0 0 0;
  background-color: #f8f8f8;
  height: 100vh;
  width: 100vw;
}

.m-account-info .part-1 img,
.m-account-info .part-2 img {
  margin-top: 15px;
}

.m-account-info .part-2-text {
  color: rgba(51, 51, 51, 1);
  font-size: 12px;
  margin: 5px 0 20px 0;
}

.m-account-info .part-3-item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: rgba(51, 51, 51, 1);
  font-size: 14px;
  padding: 12px 0;
  box-sizing: border-box;
  border-bottom: solid 1px #eee;
}
.m-account-info .part-3-item:last-child {
  border: none;
  padding-bottom: 0;
}
.m-account-info .part-3-item img {
  height: 12px;
  padding: "4px 0 0 0";
}
