.pc-item-details {
  width: 1200px;
  margin: auto;
}

.pc-item-details-breadcrumb {
  font-size: 24px;
}
.pc-item-details-breadcrumb a {
  color: #000;
  text-decoration: none;
}
.pc-item-details-part2 {
  display: flex;
  background-color: #fff;
  padding: 15px 15px 15px 15px;
  margin: 20px 0 0 0;
}
.pc-item-details-part2-right {
  padding: 0 0 0 20px;
}

.pc-item-details .choose-lable-active {
  color: rgba(255, 100, 30, 1);
  border: 1px solid rgba(255, 100, 30, 1);
}
.pc-item-details .choose-lable {
  color: rgb(160, 160, 162);
  border: 1px solid rgb(160, 160, 162);
}
