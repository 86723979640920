.HomePage {
  background: url(https://cowra-public-assets.oss-ap-southeast-7.aliyuncs.com/web/part1.jpeg)
    repeat-x;
  width: 100vw;
  /* height: 100vh; */
  padding-bottom: 155px;
}

.HomePage input::placeholder {
  color: rgba(199, 199, 205, 1);
  /* text-indent: 2em; */
}
