.pc-accounts {
  /* padding: 20px 50px 20px 50px; */
}

.pc-accounts .pc-accounts-label {
  display: inline-block;
  width: 60px;
  font-weight: bold;
  color: #bbb;
}

.pc-accounts .ant-layout-sider-trigger {
  position: relative;
}

/* account list的样式 */
.pc-account-list-modal .pc-account-list-modal-label {
  font-weight: bold;
  /* display: inline-block; */
  /* width: 120px; */
}
