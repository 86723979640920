.pc-contact .banners {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.pc-contact .pc-contact-banners {
  display: flex;
  justify-content: space-evenly;
  margin-top: 30px;
  height: 361px;
}

.pc-contact .pc-contact-banners-item-1 img {
  width: 110px;
  height: 68px;
}
.pc-contact .pc-contact-banners-item-2 img {
  width: 136px;
  height: 136px;
}

.pc-contact .pc-contact-banners-item .pc-contact-banners-item-btn {
  font-size: 14px;
  padding: 5px 20px 5px 20px;
  font-weight: bold;
  border: 1px solid rgba(2, 111, 252, 0.36);
  color: rgba(2, 111, 252, 1);
}

.pc-contact .pc-contact-banners-item-2 h4 {
  font-size: 17px;
  color: rgba(2, 111, 252, 1);
}
/* color: "#ff8300", */

.pc-contact .pc-contact-banners-item {
  position: relative;
  text-align: center;
  /* background: #fff; */
  width: 174px;
  height: 298px;
}
.pc-contact .pc-contact-banners-item-1 {
  width: 100%;
  position: absolute;
}

.pc-contact .pc-contact-banners-item-2 {
  width: 174px;
  height: 208px;
  position: absolute;
  z-index: 100;
  padding: 52px 0 0px 0;
  background-color: rgba(255, 255, 255, 0);
  backdrop-filter: blur(6px);
  border: 1px solid #fff;
  box-shadow: 0px 2px 6px #ccc;
  /* 动画 */
  transition: 1s;
}

.pc-contact .pc-contact-banners-item:nth-child(odd) .pc-contact-banners-item-2 {
  bottom: 0;
  animation: gogogo 2s infinite linear;
}
.pc-contact
  .pc-contact-banners-item:nth-child(even)
  .pc-contact-banners-item-2 {
  animation: gogogo2 2s infinite linear;
  bottom: -20px;
}

@keyframes gogogo {
  0% {
    background-color: rgba(255, 255, 255, 0.5);
  }
  50% {
    background-color: rgba(255, 255, 255, 0);
    bottom: -20px;
  }
  100% {
    background-color: rgba(255, 255, 255, 0.5);
  }
}
@keyframes gogogo2 {
  0% {
    background-color: rgba(255, 255, 255, 0.5);
  }
  50% {
    background-color: rgba(255, 255, 255, 0);
    bottom: 0px;
  }
  100% {
    background-color: rgba(255, 255, 255, 0.5);
  }
}
