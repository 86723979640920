.payment {
  background-color: #fff;
}
.payment .part-1 div {
  cursor: pointer;
  transition: 0.5s;
  padding: 18px 0 18px 0;
  background: #fff;
  color: rgb(255, 100, 30);
  text-align: center;
}

.payment .part-1 div:hover {
  background: rgb(255, 100, 30);
  color: #fff;
}
.payment .part-1 div.active {
  background: rgb(255, 100, 30);
  color: #fff;
}
